/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { securedApi } from "../service/AxiosInterceptor";

const base_api = `/report`;

export const getFirstFinancialYear = async () => {
	const response = await securedApi.get(`${base_api}/get-first-financial-year`);
	return response.data;
};

export const exportReport = async (reportBy, data, createdBy) => {
	const response = await securedApi.post(`${base_api}/export`, { reportBy, data, createdBy }, { responseType: 'blob' });
	return response.data;
};