/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { BarLoader } from "react-spinners";
import Table from "../components/Table";
import { useEffect, useState } from "react";
import { getAllApprovedReceipts } from "../../api-wrapper/ReceiptApis";
import { exportReport, getFirstFinancialYear } from "../../api-wrapper/FinancialReportApis";
import { getAllUsers } from "../../api-wrapper/UserApis";
import Dialog from '../../common/components/Dialog';

function FinancialReport() {

	const REPORT_BY = {
		CurrentFinancialYear: 'CurrentFinancialYear',
		AllFinancialYears: 'AllFinancialYears',
		CustomRange: 'CustomRange'
	}

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [allFinancialYears, setAllFinancialYears] = useState([]);
	const [reportBy, setReportBy] = useState(REPORT_BY.CurrentFinancialYear);
	const [financialYear, setFinancialYear] = useState(allFinancialYears[0]);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [receipts, setReceipts] = useState([]);
	const [displayReport, setDisplayReport] = useState(false);
	const [totalAmount, setTotalAmount] = useState(0);
	const [users, setUsers] = useState([]);
	const [createdBy, setCreatedBy] = useState('');
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	const alert = (message) => {
		setAlertMessage(message);
		setShowAlert(true);
	};
	
	useEffect(() => {

		setLoading(true);

		getFirstFinancialYear().then(year => {
			
			const tempAllFinancialYears = [];

			const date = new Date();
			let currentYear = date.getFullYear();
			let currentMonth = date.getMonth() + 1;

			let financialYear = (currentMonth >= 4) ? currentYear + "-" + (currentYear + 1) % 100 : (currentYear - 1) + "-" + currentYear % 100;
			tempAllFinancialYears.push(financialYear);

			while (financialYear !== year) {
				currentYear--;
				financialYear = (currentMonth >= 4) ? currentYear + "-" + (currentYear + 1) % 100 : (currentYear - 1) + "-" + currentYear % 100;
				tempAllFinancialYears.push(financialYear);
			}

			setAllFinancialYears(tempAllFinancialYears);
		})
		.catch(error => setError('Error occurred during fetching financial year: ', error?.response?.data))
		.finally(setLoading(false));

		const fetchData = async () => {
			try {
				const receiptsResponse = await getAllApprovedReceipts();
				const usersResponse = await getAllUsers();
				const createdByIds = receiptsResponse.map(receipt => receipt.createdBy.id);
							
				const userOptions = usersResponse
							.filter(user => createdByIds.includes(user.id))
							.map(user => ({
								value: user.id,
								label: `${user.firstName} ${user.lastName}`,
							}));
						setUsers(userOptions);
					} catch (error) {
						console.error("Error fetching data", error);
					}
				};
		fetchData();

	}, []);

	const formatDate= (dateString) => {
		const [year, month, day] = dateString.split('-');
		return `${day}-${month}-${year}`;
	}

	const formatDateCreated = (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	}

	const formatAmount = (amount) => {
		if (amount === null || amount === undefined) return '';
		return amount.toFixed(2);
	  };

	const columns = [
		{
			name: 'Receipt Id',
			selector: row => row.receiptId,
			sortable: true,
			wrap: true,
			id: 'receiptId'
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true,
			wrap: true,
			id: 'name'
		},
		{
			name: 'PAN No.',
			selector: row => row.panNumber,
			sortable: true,
			wrap: true,
			id: 'panNo'
		},
		{
			name: 'Dated',
			selector: row => formatDate(row.dated),
			sortable: true,
			wrap: true,
			id: 'dated'
		},
		{
			name: 'Created On',
			selector: row => formatDateCreated(row.createdOn),
			sortable: true,
			wrap: true,
			id: 'createdOn'
		},
		{
			name: 'Created By',
			selector: row => row.createdBy.firstName + ' ' + row.createdBy.lastName,
			wrap: true,
			id: 'createdBy'
		},
		{
			name: 'Amount',
			selector: row => row.amount,
			sortable: true,
			wrap: true,
			id: 'amount',
			cell: row => (
				<div style={{ textAlign: 'right', width: '65%' }}>
        		{formatAmount(row.amount)}
      			</div>
			  ),
			header: (
			<div style={{ textAlign: 'right', width: '65%' }}>
				Amount
			</div>
			),
		}
	]

	const handleChangeReportBy = (event) => {
		const value = event.target.value;

		setReportBy(value);
		
		if (value === REPORT_BY.AllFinancialYears) {
			setFinancialYear(allFinancialYears[0]);
		}
	};

	const handleFinancialYearChange = (event) => {
		setFinancialYear(event.target.value);
	};

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value);
	};
	
	const handleEndDateChange = (event) => {
		setEndDate(event.target.value);
	};

	const handleChangeCreatedBy = (event) => {
		setCreatedBy(event.target.value);
	};

	const handleGenerateReportClick = () => {

		setError('');
		setTotalAmount(0);
		setReceipts([]);
		setDisplayReport(false);

		let validationFailed = false;

		document.querySelectorAll('input').forEach(e => {
			if (!e.reportValidity()) {
				validationFailed = true;
				return;
			}
		});

		if (validationFailed) {
			return;
		}

		setLoading(true);
		
		getAllApprovedReceipts()
			.then(receipts => {

				let receiptsCreatedBy = receipts;

				if (createdBy !== '') {
					receiptsCreatedBy = receipts.filter(receipt => receipt.createdBy.id == createdBy);
				}
				
				const filteredReceipts = [];
				let amount = 0;

				if (reportBy === REPORT_BY.CurrentFinancialYear) {

					receiptsCreatedBy.forEach(receipt => {

						if (receipt.receiptId.includes(allFinancialYears[0])) {
							filteredReceipts.push(receipt);
							amount = amount + receipt.amount;
						}
					})

				} else if (reportBy === REPORT_BY.AllFinancialYears) {

					receiptsCreatedBy.forEach(receipt => {

						if (receipt.receiptId.includes(financialYear)) {
							filteredReceipts.push(receipt);
							amount = amount + receipt.amount;
						}
					})

				} else {

					receiptsCreatedBy.forEach(receipt => {

						if (receipt.createdOn.split(/T/)[0] >= startDate && receipt.createdOn.split(/T/)[0] <= endDate) {
							filteredReceipts.push(receipt);
							amount = amount + receipt.amount;
						}
					})
				}

				setTotalAmount(amount);
				setReceipts(filteredReceipts);
				setDisplayReport(true);
			})
			.catch(error => setError('Error occurred during fetching receipts: ', error?.response?.data))
			.finally(setLoading(false));
	};

	const handleExport = async () => {
		try {
			const response = await exportReport(
				reportBy, 
				reportBy === REPORT_BY.CurrentFinancialYear ? allFinancialYears[0] : reportBy === REPORT_BY.AllFinancialYears ? financialYear : [startDate, endDate],
				createdBy
			);
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'Report.pdf');
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			
			if (error.response.status === 400) {
				alert('Error: Invalid data');
				console.log("Invalid data.");
			}
			else if(error.response.status === 404){
				alert('Company profile not available.');
				console.log("Company profile not available.");
			}
			else if(error.response.status === 422){
				alert('No receipts available.');
				console.log("No receipts available.");
			}			
			else{
				console.error('Error downloading report PDF:', error);
				alert('Something went wrong!')
			}
		}
	}

	return(
		<div className='container-fluid' id='financialReportPage'>
			<BarLoader loading={loading} width={'100%'} id='loader' />
			<Dialog 
				show={showAlert} 
				message={alertMessage} 
				confirmType={false}
				onOk={() => {
					setShowAlert(false);
					setAlertMessage('');
				}} />

			{(error !== '') && 
				<div className='page-error' style={{justifyContent: 'center'}} id='pageError'>
					{error}
					<button className='close' onClick={() => setError('')} id='closeButton'>
						&#10006;
					</button>
				</div>
			}
			
			<div className='form-row' style = {{ justifyContent : 'center' }} id='reportGenerator'>
				<div className='form-field-container'>
					<label htmlFor='createdBy' id='createdByLabel'>Created By</label>
					<select
						name='createdBy'
						id='createdBy'
						className='filter-box-input'
						value={createdBy}
						onChange={handleChangeCreatedBy}
					>
						<option value=''>All Users</option>
						{users.map(user => (
							<option key={user.value} value={user.value}>
								{user.label}
							</option>
						))}
					</select>
				</div>
				<div className='form-field-container'>
					<label htmlFor='reportBy' id='reportByLabel'>Report Of</label>
					<select
						name='reportBy'
						id='reportBy'
						className='filter-box-input'
						value={reportBy}
						onChange={handleChangeReportBy}
					>
						<option value={REPORT_BY.CurrentFinancialYear}>Current Financial Year</option>
						<option value={REPORT_BY.AllFinancialYears}>All Financial Years</option>
						<option value={REPORT_BY.CustomRange}>Custom Range</option>
					</select>
				</div>
				{reportBy === REPORT_BY.CurrentFinancialYear ? <></> : reportBy === REPORT_BY.AllFinancialYears ?
					<div className='form-field-container'>
						<label htmlFor='financialYears' id='financialYearsLabel'>Financial Years</label>
						<select
							name='financialYears'
							id='financialYears'
							className='filter-box-input'
							value={financialYear}
							onChange={handleFinancialYearChange}
						>
							{allFinancialYears.map((year, i) => {
								return <option key={i} value={year}>{year}</option>;
							})}
						</select>
					</div>
					:
					<>
						<div className='form-field-container'>
							<label htmlFor='startDate' id='startDateLabel'>Start Date</label>
							<input
								type='date'
								id='startDate'
								name='startDate'
								className='filter-box-input'
								value={startDate}
								onChange={handleStartDateChange}
								max={endDate}
								required
							/>
						</div>
						<div className='form-field-container'>
							<label htmlFor='endDate' id='endDateLabel'>End Date</label>
							<input
								type='date'
								id='endDate'
								name='endDate'
								className='filter-box-input'
								value={endDate}
								onChange={handleEndDateChange}
								min={startDate}
								required
							/>
						</div>
					</>
				}
				<div className='form-field-container' style = {{ alignSelf : 'end' }}>
					<button onClick={handleGenerateReportClick} className='btn btn-primary' style={{ height : '40px' }} id='generateReportButton'>
						Generate Report
					</button>
				</div>
			</div>

			{displayReport &&
				<div id='report'>
					<Table
						columns={columns}
						data={receipts}
						conditionalStyle={false} />
					<br />
					<div style={{textAlign: 'center', fontSize: '130%'}} id='receiptDetails'>
						<p id='totalApprovedReceipts'><span style={{color: '#000000DE'}}>Total number of approved receipt:</span> {receipts.length}</p>
						<p id='totalAmount'><span style={{color: '#000000DE'}}>Total amount:</span> {totalAmount}</p>
						<br />
						<button onClick={handleExport} className='btn btn-outline-primary' id='exportButton'>Export</button>
						<br /><br />
					</div>
				</div>
			}
		</div>
	);
}

export default FinancialReport;