/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { useEffect, useState, useRef } from 'react';
import { deleteReceipt, getAllReceipts, updateReceiptStatus, downloadReceipt, printReceipt, emailReceipt } from '../../api-wrapper/ReceiptApis';
import Table from '../../common/components/Table';
import { getAllUsers } from '../../api-wrapper/UserApis';
import {exportXls} from '../../api-wrapper/ExportXlsApi';
import { RECEIPT_STATUS, RECEIPT_STATUS_COLOR ,PAYMENT_MODE} from '../../common/Variables';
import { BarLoader } from 'react-spinners';
import Dialog from '../../common/components/Dialog';
import { useNavigate,useLocation } from "react-router";


function Reviewer() {
	const [loading, setLoading] = useState(false);
	const [receipts, setReceipts] = useState([]);
	const [users, setUsers] = useState([]);
	const [filteredReceipts, setFilteredReceipts] = useState([]);
	const navigate = useNavigate();
	const [filters, setFilters] = useState({
		status: '',
		startDate: '',
		endDate: '',
		createdBy: ''
	});
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [showConfirm, setShowConfirm] = useState(false);
	const [confirmMessage, setConfirmMessage] = useState('');
	const [rowToUpdate, setRowToUpdate] = useState(null);
	const [action, setAction] = useState(null);
	const [pageError,setPageError]=useState('');
	const [showReasonToDecline,setShowReasonToDecline] = useState(false);
	const [reasonToDecline,setReasonToDecline]=useState('');
	const afterUpdateCallback = useRef(null);
	const location = useLocation();

	const ACTION_TYPE = {
		Approve: 0,
		Decline: 1,
		Delete: 2
	}

	const getActionTypeString = (actionNumber) => {
		for (const [key, value] of Object.entries(ACTION_TYPE)) {
			if (value === actionNumber) {
				return key;
			}
		}
	};

	const getStausNameString = (status) =>{
		for(const [key,value] of Object.entries(RECEIPT_STATUS)){
			if( value === status) {
				return key;
			}
		}
	};

	useEffect(() => {
		if (location.state?.errorMessage) {
			setPageError(location.state.errorMessage);
			navigate('/dashboard', { replace: true, state: {} });
		  }
		const fetchData = async () => {
			try {
				const receiptsResponse = await getAllReceipts();
				const usersResponse = await getAllUsers();
				setReceipts(receiptsResponse);
				const createdByIds = receiptsResponse.map(receipt => receipt.createdBy.id);
				const userOptions = usersResponse
					.filter(user => createdByIds.includes(user.id))
					.map(user => ({
						value: user.id,
						label: `${user.firstName} ${user.lastName}`,
					}));
				setUsers(userOptions);
			} catch (error) {
				console.error("Error fetching data", error);
			}
		};
	
		fetchData();
	}, [location.state, navigate]);

	useEffect(() => {
        if (pageError !== '')
			setTimeout(() => {
				setPageError('');
			}, 3500);
    }, [pageError]);
	
	const columns = [
		{
			name: 'Receipt Id',
			selector: row => row.receiptId,
			sortable: true,
			wrap: true,
			id: 'receiptId'
		},
		{
			name: 'Created On',
			selector: row => {
				let createdDate = new Date(row.createdOn);
					let day = createdDate.getDate();
					let month = createdDate.getMonth() + 1;
					let year = createdDate.getFullYear().toString();
					return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
			},
			sortable: true,
			wrap: true,
			id: 'createdOn'
		},
		{
			name: 'Dated',
			selector: row => {
				let createdDate = new Date(row.dated);
					let day = createdDate.getDate();
					let month = createdDate.getMonth() + 1;
					let year = createdDate.getFullYear().toString();
					return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
			},
			sortable: true,
			wrap: true,
			id: 'dated'
		},
		{
			name: 'Created By',
			selector: row => {
				if (row.createdBy) {
				  return row.createdBy.firstName + ' ' + row.createdBy.lastName;
				} else {
				  return 'unknown'; 
				}
			  },
			wrap: true,
			id: 'createdBy'
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true,
			wrap: true,
			id: 'name'
		},
		{
			name: 'Email',
			selector: row => row.email,
			wrap: true,
			grow: 1.5,
			id: 'email'
		},
		{
			name: 'Address',
			selector: row => row.address,
			wrap: true,
			grow: 2,
			id: 'address'
		},
		{
			name: 'Amount',
			selector: row => row.amount,
			sortable: true,
			wrap: true,
			id: 'amount'
		},
		{
			name: 'Payment Mode',
			selector: row => PAYMENT_MODE[row.paymentMode],
			wrap: true,
			id: 'paymentMode'
		},
		{
			name: 'Transaction/Cheque Number',
			selector: row => row.transactionNumber,
			wrap: true,
			id: 'transactionNumber'
		},
		{
			name: 'PAN Number',
			selector: row => row.panNumber,
			wrap: true,
			id: 'panNumber'
		},
		{
			name: 'Drawn On',
			selector: row => row.drawnOn,
			wrap: true,
			id: 'drawnOn'
		},
		{
			name: 'Status',
			grow: 1,
			cell: row => {
				const statusColor = {
					APPROVED: RECEIPT_STATUS_COLOR.Approved,
					DECLINED: RECEIPT_STATUS_COLOR.Declined,
					PENDING: RECEIPT_STATUS_COLOR.Pending,
				}[getStausNameString(row.receiptStatus)];
		
				return (
					<div
						style={{
							backgroundColor: statusColor,
							padding: '10px',
							borderRadius: '10px',
						}}
						id='receiptStatus'
					>
						{getStausNameString(row.receiptStatus)}
					</div>
				);
			},
			id: 'status'
		},
		{
			name: 'Action',
			cell: (row) => (
				<div className='button-container' style={{'display': 'flex', 'gap': '10px', alignItems: 'center'}} id='actionButtons'>
					{row.receiptStatus !== RECEIPT_STATUS.APPROVED && row.receiptStatus !== RECEIPT_STATUS.DECLINED && (
								<button
									className='btn btn-outline-info'
									onClick={e => navigate(`/dashboard/edit/${row.id}`)}
									id={`editButton-${row.id}`}
								>
									Edit
								</button>
							)}
				
					{row.receiptStatus === RECEIPT_STATUS.PENDING && (
						<>
							<button
								className='btn btn-outline-success'
								onClick={async (e) => confirm(row, ACTION_TYPE.Approve)}
								id={`approveButton-${row.id}`}
							>
								Approve
							</button>
							<button
								className='btn btn-outline-secondary'
								onClick={async (e) => confirm(row, ACTION_TYPE.Decline)}
								id={`declineButton-${row.id}`}
							>
								Decline
							</button>
						</>
					)}
					{row.receiptStatus !== RECEIPT_STATUS.APPROVED && (
						<>
							<button
								className='btn btn-outline-danger'
								onClick={async (e) => confirm(row, ACTION_TYPE.Delete)}
								id={`deleteButton-${row.id}`}
							>
								Delete
							</button>
						</>
					)}
					{row.receiptStatus === RECEIPT_STATUS.DECLINED && (
						row.reasonToDecline &&
						<>
							<div className='reason-container' style={{ display: 'flex', alignItems: 'center' }}>
                    			<label className='reason-label'>Reason: {row.reasonToDecline}</label>
                			</div>
						</>
						)}
					{row.receiptStatus === RECEIPT_STATUS.APPROVED && (
						<>
							<button
								className='btn btn-outline-primary'
								onClick={async (e) => {
									setLoading(true);
									try {
										const response = await downloadReceipt(row.id);
										const url = window.URL.createObjectURL(new Blob([response]));
										const link = document.createElement('a');
										link.href = url;
										link.setAttribute('download', `Receipt_${row.receiptId}.pdf`);
										document.body.appendChild(link);
										link.click();
									} catch (error) {
										if (error.response.status === 400) {
											alert('Make sure..\n1. Receipt Id is valid\n2. Receipt is approved\n3. Company profile is created');
										}
										console.error('Error downloading PDF:', error);
									} finally {
										setLoading(false);
									}
								}}
								id={`downloadButton-${row.id}`}
							>
								Download
							</button>
							<button
								className='btn btn-outline-secondary'
								onClick={async (e) => {
									setLoading(true);
									try {
										const response = await printReceipt(row.id);
										const file = new Blob([response], { type: 'application/pdf' });
										const fileURL = URL.createObjectURL(file);
										const iframe = document.createElement('iframe');
										iframe.style.display = 'none';
										iframe.src = fileURL;
										document.body.appendChild(iframe);
										iframe.contentWindow?.print(); // Print the PDF
									} catch (error) {
										if (error.response.status === 400) {
											alert('Make sure..\n1. Receipt Id is valid\n2. Receipt is approved\n3. Company profile is created');
										}
										console.error('Error printing Receipt.:', error);
									} finally {
										setLoading(false);
									}
								}}
								id={`printButton-${row.id}`}
							>
								Print
							</button>
							{row.email && 
								<button
									className = {`btn ${row.emailed ? 'btn-outline-success' : 'btn-outline-secondary'}`}
									onClick = {async (e) => {
										setLoading(true);
										try {
											const response = await emailReceipt(row.id);
											if (response) {

												alert('Email sent successfully');
												const updatedReceipt = { ...response}; 
												setReceipts(receipts.map(receipt => receipt.id === row.id ? updatedReceipt : receipt));
											}
											else{
												alert("Email could not be sent. Try again later.");
											}
										} catch (error) {
											if (error.response.status === 400) {
												alert('Make sure..\n1. Receipt Id is valid\n2. Receipt is approved\n3. Company profile is created');
											}
											else
												alert("Email could not be sent. Try again later.");
										} finally {
											setLoading(false);
										}
									}}
									id={`emailButton-${row.id}`}
								>
									{row.emailed ? 'Emailed' : 'Email'}
								</button>
							}
						</>
					)}
				</div>
			),
			grow: 2,
			id: 'action'
		}
	]

	const handleFilterChange = (event) => {
		const { name, value } = event.target;
		setFilters({ ...filters, [name]: value });
	};

	useEffect(() => {
		const filteredData = receipts.filter((receipt) => {
			let shouldInclude = true;

			const statusFilterValue = filters.status ? RECEIPT_STATUS[filters.status] : null;

			if (statusFilterValue !== null && statusFilterValue !== receipt.receiptStatus) {
				shouldInclude = false;
			}
	
			if (filters.startDate && receipt.createdOn.split(/T/)[0] < filters.startDate) {
				shouldInclude = false;
			}

			if (filters.endDate && receipt.createdOn.split(/T/)[0] > filters.endDate) {
				shouldInclude = false;
			}

			if (filters.createdBy && filters.createdBy != receipt.createdBy.id) {
				shouldInclude = false;
			}

			return shouldInclude;
		});

		setFilteredReceipts(filteredData);
	}, [filters, receipts]);

	const clearFilters = () => {
		setFilters({ status: '', startDate: '', endDate: '', createdBy: '' });
		setFilteredReceipts(receipts);
	};

	const alert = (message) => {
		setAlertMessage(message);
		setShowAlert(true);
	};

	const confirm = (row, action) => {
		setAction(action);
		setRowToUpdate(row);
		if(action === ACTION_TYPE.Decline){
			setShowReasonToDecline(true);
			}
		else{
			const receiptIdElement = <strong>{row.receiptId}</strong>;
			setConfirmMessage(
				action === ACTION_TYPE.Delete ? 
				<>Do you really want to delete this receipt with id {receiptIdElement}?</> :
				`Are you sure, you want to ${getActionTypeString(action)} this receipt?`);
			setShowConfirm(true);
		}
	}

	const handleCancel = () => {
		setShowConfirm(false);
		setAction(null);
		setRowToUpdate(null);
		setConfirmMessage('');
	}

	const handleExport = async() =>{
		try {
			const response = await exportXls(
				filters.status,filters.startDate,filters.endDate,filters.createdBy
			);
			if(response.status == 200)
			{
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Report.xlsx');
				document.body.appendChild(link);
				link.click();
			}
			if(response.status == 204)
			{
				setPageError("No records available.");
			}
		} catch (error) {
			setPageError("Error exporting records. Try again later.");
			console.log(error);
		}
	}

	const handleConfirm = (response) => {
		handleCancel();

		const action = response.action;
		const row = response.data;

		switch (action) {
			case ACTION_TYPE.Approve:
				return approveReceipt(row,RECEIPT_STATUS.APPROVED);
			
			case ACTION_TYPE.Delete:
				return delReceipt(row);
		
			default:
				return;
		}
	}

	const handleCancelReasonToDecline = ()=>{
		setAction(null);
		setShowReasonToDecline(false);
		setReasonToDecline("");
		setRowToUpdate(null);

	}

	const handleConfirmReasonToDecline = (row) =>{
		setAction(null);
		setReasonToDecline(row.data);
		setShowReasonToDecline(false);
		afterUpdateCallback.current = () => {
			const updatedRowWithReason = { ...rowToUpdate, reasonToDecline: reasonToDecline };	
			declineReceipt(updatedRowWithReason,RECEIPT_STATUS.DECLINED);
		  };
	}

	useEffect(() => {
		if (afterUpdateCallback.current) {
		  afterUpdateCallback.current();
		  afterUpdateCallback.current = null;
		}
	  }, [reasonToDecline, showReasonToDecline]);

	const approveReceipt = async (row,status) => {
		setLoading(true);
		delete row.createdOn;
		delete row.createdBy;
		delete row.editedBy;
				
		try {			
			const response = await updateReceiptStatus(row,getStausNameString(status));
			
			if (response) {
				const updatedReceipt = { ...response };
				setReceipts(receipts.map(receipt => receipt.id === row.id ? updatedReceipt : receipt));
			}
		} catch (error) {
			if(error.response?.data?.includes('The receipt has been updated')){
				setAlertMessage("The receipt has been updated. Fetching updated reciepts.");
				setShowAlert(true);
				setLoading(true);
				getAllReceipts().then(response =>{
					setReceipts(response);
				});
				setLoading(false);
			}
			else
			{
				setPageError('Error processing your request. Please try again.');
				console.error('Error updating receipt status:', error);
			}
			
		} finally {
			setLoading(false);
		}
	}

	const declineReceipt = async (row,status) => {
		setLoading(true);
		delete row.createdOn;
		delete row.createdBy;
		delete row.editedBy;
		try {

			const response = await updateReceiptStatus(row, getStausNameString(status));

			if (response) {
				const updatedReceipt = { ...response}; 
				setReceipts(receipts.map(receipt => receipt.id === row.id ? updatedReceipt : receipt));
			}
		} catch (error) {
			if(error.response?.data?.includes('The receipt has been updated')){
				setAlertMessage("The receipt has been updated. Fetching updated reciepts.");
				setShowAlert(true);
				setLoading(true);
				getAllReceipts().then(response =>{
					setReceipts(response);
					const date= new Date();
				});
				setLoading(false);
			}
			else
			{
				setPageError('Error processing your request. Please try again.')
				console.error('Error updating receipt status:', error);
			}
		} finally {
			setLoading(false);
			setReasonToDecline("");
			setRowToUpdate(null);
		}
	}

	const delReceipt = async (row) => {
		setLoading(true);
		try {
			const response = await deleteReceipt(row.id);

			if (response) {
				setReceipts(receipts.filter((receipt) => receipt.id !== row.id));
			}
		} catch (error) {
			setPageError('Error processing your request. Please try again.');
			console.error('Error deleting receipt:', error);
		} finally {
			setLoading(false);
		}
	}

	return (
		<div className='container-fluid' id='reviewerDashboard'>
			<Dialog 
				show={showAlert} 
				message={alertMessage} 
				confirmType={false}
				onOk={() => {
					setShowAlert(false);
					setAlertMessage('');
				}} />
			<Dialog 
				show={showConfirm} 
				message={confirmMessage} 
				onCancel={handleCancel}
				onOk={(response) => handleConfirm(response)}
				action={action}
				data={rowToUpdate} />
			<Dialog 
				show={showReasonToDecline}
				message={  <div>
					<label>Are you sure you want to decline the receipt?<br></br> If yes, Please provide reason to decline:</label>
					<input
						style={{ width: '100%' }}
						type="text"
						onChange={(e) => {
                            const trimmedValue = e.target.value.trimEnd();
                            setReasonToDecline(trimmedValue);
                        }}
						maxLength="100"
						placeholder="Reason To Decline"
					/>
				</div>} 
				onCancel={handleCancelReasonToDecline}
				onOk={(row) => handleConfirmReasonToDecline(row)}
				action={action}
				data={reasonToDecline} />
			<BarLoader loading={loading} width={'100%'} id='loader' />
			<div style={{height:"30px" ,justifyContent : 'flex-start'}}>
				{(pageError !== '') && <div className='page-error' id='pageError'>{pageError}</div>}
			</div>

			<div className='form-row' style = {{ justifyContent : 'flex-end' }} id='filters'>
				<div className='form-field-container'>
					<label htmlFor='status' id='statusLabel'>Status</label>
					<select
						name='status'
						id='status'
						className='filter-box-input'
						value={filters.status}
						onChange={handleFilterChange}
					>
						<option value=''>All Statuses</option>

						{Object.entries(RECEIPT_STATUS).map(([key, value]) => (
							<option key={key} value={key}>
								{key}  
							</option>
        ))}
					</select>
				</div>
				<div className='form-field-container'>
					<label htmlFor='startDate' id='startDateLabel'>Start Date</label>
					<input
						type='date'
						id='startDate'
						name='startDate'
						className='filter-box-input'
						value={filters.startDate}
						onChange={handleFilterChange}
						max={filters.endDate}
					/>
				</div>
				<div className='form-field-container'>
					<label htmlFor='endDate' id='endDateLabel'>End Date</label>
					<input
						type='date'
						id='endDate'
						name='endDate'
						className='filter-box-input'
						value={filters.endDate}
						onChange={handleFilterChange}
						min={filters.startDate}
					/>
				</div>
				<div className='form-field-container'>
					<label htmlFor='createdBy' id='createdByLabel'>Created By</label>
					<select
						name='createdBy'
						id='createdBy'
						className='filter-box-input'
						value={filters.createdBy}
						onChange={handleFilterChange}
					>
						<option value=''>All Users</option>
						{users.map(user => (
						<option key={user.value} value={user.value}>
							{user.label}
						</option>
						))}
					</select>
				</div>
				<div className='form-field-container' style = {{ alignSelf : 'end' }}>
					<button onClick={clearFilters} className='btn btn-secondary' style={{ height : '40px' }} id='clearFiltersButton'>
						Clear Filters
					</button>
				</div>
			</div>
			
			<Table
				columns={columns}
				data={(filters.status !== '' || filters.createdBy !== '' || 
					filters.startDate !== '' || filters.endDate !== '') ? 
					filteredReceipts : receipts}
					conditionalStyle={false} />

			<button
				className='btn btn-primary'
				style={{margin:15}}
				onClick={handleExport}
				id='xlsExportButton'
				>Export
			</button>
		</div>
	)
}

export default Reviewer;